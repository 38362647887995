<template>
  <v-app>
    <AppHeader
      v-if="$route.name != 'notfound' && $route.name != 'login'"
    ></AppHeader>
    <AsideMenu
      v-if="$route.name != 'notfound' && $route.name != 'login'"
    ></AsideMenu>
    <v-main class="body">
      <router-view />

      <v-snackbar
        timeout="6000000"
        width="auto"
        right
        v-model="$store.state.connection_snack"
      >
        لايوجد اتصال بالأنترنت
        <template v-slot:action="{ attrs }">
          <v-icon color="white" v-bind="attrs">wifi_off</v-icon>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
<style scoped></style>
<script>
import AppHeader from "./layout/AppHeader.vue";
import AsideMenu from "./layout/AsideMenu.vue";
export default {
  name: "App",
  data: () => ({}),
  watch: {
    $route() {
      if (this.$store.getters.loggedin) {
        this.$store.dispatch("get_langs");
      }
    },
  },
  methods: {},
  components: {
    AppHeader,
    AsideMenu,
  },
  methods: {
    count_orders() {
      this.$store.dispatch("get_orders").then((res) => {
        // console.log(res);
        this.$store.state.curr_orders = res.current;
        if (this.$store.state.orders.length == 0) {
          this.$store.state.orders = res.new;
          if (res.new.length != 0) {
            this.$store.state.new_orders = true;
          }
        } else {
          for (let i = 0; i < res.new.length; i++) {
            if (!this.$store.state.orders.find((s) => s.id == res.new[i].id)) {
              this.$store.state.new_orders = true;
              this.$store.state.orders.push(e);
              break;
            }
          }

          for (let i = 0; i < this.$store.state.orders.length; i++) {
            if (!res.new.find((s) => s.id == this.$store.state.orders[i].id)) {
              this.$store.state.orders.splice(i, 1);
              break;
            }
          }
        }
      });
    },
  },
  beforeMount() {
    if (this.$store.getters.loggedin) {
      setInterval(() => {
        this.count_orders();
      }, 30000);
      if (this.$store.getters.loggedin) {
        this.$store.dispatch("get_langs");
      }
      this.$store.dispatch("get_settings").then((res) => {
        this.$vuetify.theme.themes.light.primary = res.primary_color;
      });
    }
  },
  created() {},
};
</script>

<style>
.label-map {
  background-color: #fff;
  position: relative;
  top: -30px;
  padding: 5px;
  color: #fff;
  border: 2px solid #ddd;
  border-radius: 8px;
  text-align: right;

  direction: ltr;
}
</style>
