import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(VueAxios, axios);
Vue.use(Vuex);

import { EncryptStorage } from "encrypt-storage";
export const encryptStorage = new EncryptStorage("secret-key-value", {
  prefix: "@talabk",
  encAlgorithm: "Rabbit",
});
export default new Vuex.Store({
  state: {
    connection_snack: false,
    menu: true,
    //def_path: "http://127.0.0.1:8000",
    def_path: "https://app.tlbak.com/",
    store_info: {
      permissions: [],
      store_name: "",
    },
    user_talabak_user:
      JSON.parse(localStorage.getItem("user_talabak_user")) || null,
    token: encryptStorage.getItem("token_talabak_user") || null,
    langs: [],
    settings: {},
    orders: [],
    curr_orders: [],
    new_orders: false,
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    langs(stat) {
      return stat.langs;
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    logout(state) {
      state.token = null;
      encryptStorage.removeItem("token_talabak_user");
    },
    user_talabak_user(state, user) {
      state.user_talabak_user = user;
    },
    settings(state, settings) {
      state.settings = settings;
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone_number", cred.phone_number);
        formData.append("password", cred.password);

        var config = {
          method: "post",
          url: "login",
          headers: {
            Accept: "application/json",
          },
          data: formData,
        };
        axios(config)
          .then((res) => {
            const token = res.data.data.token;
            encryptStorage.setItem("token_talabak_user", token);
            context.commit("login", token);

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": cred.config.type
              ? cred.config.type
              : "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };
        axios(config)
          .then((res) => {
            resolve(res);
            context.state.connection_snack = false;
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            if (!err.response) {
              context.state.connection_snack = true;
            } else {
              reject(err);
            }
            if (err.response.status == 401) {
              context.commit("logout");
              router.push("/");
            }
          });
      });
    },
    get_langs(context, cred) {
      context
        .dispatch("public__request", {
          config: {
            url: "languages",
            method: "get",
          },
        })
        .then((res) => {
          context.state.langs = Object.assign([], res.data.data);
        });
    },
    get_settings(context, cred) {
      return new Promise((resolve, rej) => {
        context
          .dispatch("public__request", {
            config: {
              url: "public_settings",
              method: "get",
            },
          })
          .then((res) => {
            context.state.settings = Object.assign({}, res.data.data);
            resolve(res.data.data);
          });
      });
    },
    get_orders(context) {
      return new Promise((resolve, reject) => {
        context
          .dispatch("public__request", {
            config: {
              url: `orders_count`,
              method: "get",
            },
          })
          .then((res) => {
            resolve(res.data.data);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
  },
  modules: {},
});
