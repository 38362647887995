<template>
  <div class="header mb-5">
    <v-app-bar app color="white" flat height="75">
      <v-container class="py-0 fill-height">
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            @click="$store.state.menu = !$store.state.menu"
            height="20"
            width="20"
            color="primary"
            class="me-4"
          ></v-app-bar-nav-icon>
        </div>
        <v-spacer> </v-spacer>
        <v-skeleton-loader v-if="!$store.state.settings.logo" width="70" height="50" type="card"></v-skeleton-loader>
        <img width="70"  v-else :src="`${$store.state.def_path}/${$store.state.settings.logo}`" alt="logo">
        <v-spacer> </v-spacer>
        <div class="d-flex align-center">

          <v-btn @click="new_orders()"  icon small  class="me-5">
            <v-badge offset-x="5" offset-y="5"  :value="$store.state.new_orders" color="error lighten-2" dot>
              <v-icon :class="{'not_icon' : $store.state.new_orders}" :color="$store.state.new_orders ? 'primary' : 'grey' ">notifications_active</v-icon>
            </v-badge>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                class="rounded-lg header-menu btn-drop-down black--text d-block"
                elevation="1"
                v-bind="attrs"
                v-on="on"
                color="white"
              >
                <v-avatar size="40">
                  <img
                  src="@/assets/user.webp"
                  />
                </v-avatar>
                <div class="d-none d-lg-block">
                  <h4 class="black--text ms-2 text-capitalize">
                      Admin
                  </h4>
                </div>
                <v-icon class="d-none d-md-block" right>
                  keyboard_arrow_down
                </v-icon>
              </v-btn>
            </template>
            <v-list dense class="menu-no-active">
              <v-list-item-group color="primary">
                <v-list-item @click="logout()">
                  <v-list-item-icon class="me-1">
                    <v-icon>logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>تسجيل الخروج </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <!--/menu -->
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .avatar {
    position: relative;
    top: 3px;
  }
  .v-toolbar__content {
    border-bottom: 1px solid #ddd;
  }
  .language {
    width: 50px;
    font-size: 13px;
    .v-select__selections {
      font-weight: bold;
      font-size: 14px;
      padding: 0;
    }
    fieldset {
      border: none !important;
    }
    .v-input__icon {
      display: none;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      > .v-input__control
      > .v-input__slot,
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      padding: 0;
    }
  }
  //---- language

  .lang-item {
    min-height: 28px !important;
    font-size: 12px !important;
  }
  .v-btn:not(.v-btn--round).v-size--default.header-menu {
    height: auto;
    padding: 3px 5px 3px 25px;
    @media (max-width: 991px) {
      padding: 5px;
      min-width: auto;
      height: 50px !important;
      width: 50px !important;
      border-radius: 50% !important;
    }
  }
  .not_icon {
    animation: move 0.1s linear 1s infinite;
    transform: rotate(0);
  }

  @keyframes move {
    0%,100%{
      transform:rotate(0)
    }
    50% {
      transform: rotate(-8deg);
    }
    75% {
      transform:rotate(8deg)
    }
  }
}
</style>
<script>

export default {
  name: "AppHeader",
  data: () => ({
    size: 1000,

    lang: {
      val: {
        name: "Arbic",
        shortCut: "Ar",
        icon: "ar.png",
      },
      langs: [
        {
          id: 1,
          name: "Arbic",
          shortCut: "Ar",
          icon: "ar.png",
        },
        {
          id: 2,
          name: "English",
          shortCut: "En",
          icon: "en.png",
        },
      ],
    },
    items: [
      { text: "البروفيل", icon: "mdi-account" },
      { text: "تسجيل الخروج", icon: "logout" },
    ],
  }),
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  new_orders() {
    this.$store.state.new_orders = false;
    if(this.$route.name != "newOrders") {
      this.$router.push("/newOrders");
    }

  },
    logout() {
      let vm = this;
      this.$store.commit("logout");
      setTimeout(() => {
        vm.$router.push({ name: "login" });
      }, 500);
    },
  },
};
</script>
