<template>
  <div>
    <v-navigation-drawer
      color="white"
      class="app-menu"
      v-model="$store.state.menu"
      app
      :right="rtl"
      width="200"
    >
      <v-list-item
        dense
        active-class="primary white--text"
        class="black--text py-1 px-3 mt-3"
        style="height: 25px"
        to="/dashboard"
        link
      >
        <v-list-item-title class="font-weight-bold"
          ><v-icon small>home</v-icon> الرئيسية
        </v-list-item-title>
      </v-list-item>

      <v-expansion-panels class="text-right" flat accordion>
        <v-expansion-panel
          class="transparent"
          v-for="(group, i) in menu"
          :key="i"
        >
          <v-expansion-panel-header
            background-color="transparent"
            class="justify-start px-2"
          >
            <div class="font-weight-bold" style="font-size: 13px">
              <v-icon small>
                {{ group.icon }}
              </v-icon>
              {{ group.group_name }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="grey lighten-4">
            <v-list-item
              dense
              v-for="(item, x) in group.items"
              :key="x"
              active-class="primary white--text"
              class="black--text py-1 px-3"
              style="height: 25px"
              :to="item.link"
              link
            >
              <v-list-item-title class="font-weight-bold">{{
                item.name
              }}</v-list-item-title>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!--/ company -->
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
  .app-menu {
    .v-navigation-drawer__content {
      .list {
        padding-bottom: 100px !important;
      }
      &::-webkit-scrollbar {
        width: 0;
        scrollbar-width: none;
      }
    }
  }
}
</style>

<script>
export default {
  name: "AsideMenu",
  data() {
    return {
      menu: [
        {
          group_name: "المنتجات",
          is_active: false,
          icon: "inventory_2",
          items: [
            {
              name: "المنتجات",
              link: "/products",
            },
            {
              name: "تعديل المنتجات",
              link: "/q_products",
            },
            {
              name: "الأقسام",
              link: "/sections",
              icon: "",
            },
            {
              name: "السمات",
              link: "/attributes",
            },
            {
              name: "المحتوي",
              link: "/contents",
            },
            {
              name: "المجموعات",
              link: "/groups",
            },
            {
              name: "الماركات",
              link: "/brands",
            },
            {
              name: "الضرائب",
              link: "/taxs",
            },
            {
              name: "البنرات",
              link: "/banners",
            },
            {
              name: "البلوكات",
              link: "/blocks",
            },
            {
              name: "الشيفات",
              link: "/chefs",
            },
            {
              name: "الوصفات",
              link: "/recipes",
            },
          ],
        },
        {
          group_name: "الطلبات",
          is_active: false,
          icon: "receipt_long",
          items: [
            {
              name: "التقارير",
              link: "/reports",
            },
            {
              name: "الطلبات",
              link: "/orders",
            },
            {
              name: "الخريطة",
              link: "/locations",
            },
            {
              name: "التقييمات",
              link: "/rates",
            },
          ],
        },
        {
          group_name: "المدفوعات والمصاريف",
          is_active: false,
          icon: "payments",
          items: [
            {
              name: "المدفوعات",
              link: "/payments",
            },
            {
              name: "المصروفات",
              link: "/expenses",
            },
            {
              name: "عمليات الدفع",
              link: "/transactions",
            },
          ],
        },
        {
          group_name: "المستخدمين",
          is_active: false,
          icon: "people",
          items: [
            {
              name: "الموظفين",
              link: "/employees",
            },
            {
              name: "الديلفير",
              link: "/deliveries",
            },
            {
              name: "العملاء",
              link: "/customers",
            },
          ],
        },
        {
          group_name: "الحملات والقطع",
          is_active: false,
          icon: "people",
          items: [
            {
              name: "الحملات",
              link: "/campaigns",
            },
            {
              name: "الكوبونات",
              link: "/coupons",
            },
            {
              name: "القطع",
              link: "/pieces",
            },
            {
              name: "كميات القطع",
              link: "/pieces_quantity",
            },
            {
              name: "صلاحيات القطع",
              link: "/expire",
            },
            {
              name: "القطع المسترجعة",
              link: "/returner",
            },
          ],
        },
        {
          group_name: "عام",
          icon: "public",
          is_active: false,
          items: [
            {
              name: "المخازن",
              link: "/storages",
              icon: "",
            },
            {
              name: "الاماكن",
              link: "/places",
              icon: "",
            },
            {
              name: "المناطقة المميزة",
              link: "/special",
              icon: "",
            },
            {
              name: "الموردين",
              link: "/suppliers",
              icon: "",
            },
            {
              name: "طلبيات الموردين",
              link: "/suppliers_orders",
              icon: "",
            },
            {
              name: "الصفحات التعريفية",
              link: "/definitions",
              icon: "",
            },
            {
              name: "السجلات",
              link: "/logs",
              icon: "",
            },
            {
              name: "الترجمة",
              link: "/translations",
              icon: "",
            },
            {
              name: "الأعدادات",
              link: "/settings",
              icon: "",
            },
          ],
        },
      ],
    };
  },
  computed: {
    rtl() {
      return this.$vuetify.rtl;
    },
    keys() {
      return this.$store.getters.lang("menu");
    },
    active_list() {
      // this.menu.foreach((e)=> {
      //   e.items.foreach((x) => {
      //     if(x.) {
      //     }
      //   })
      // })
    },
  },
  created() {},
};
</script>
