<template>
  <v-container>
    <div class="notfound d-flex align-center jusitfy-center">
      <div class="text-cennter">
        <img class="notfound__img" src="../assets/image.png" alt="" />
        <h1 class="text-center">لا يمكن الحصول علي عنوان الرابط
        </h1>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "notfound",
};
</script>

<style lang="scss" scoped>
.notfound {
  margin: 0 auto;
  max-width: 500px;
  &__img {
    margin-top:100px;
    max-width: 400px;
  }
}
</style>
